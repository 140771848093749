import React from 'react'
import propTypes from 'prop-types'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import ConsentManagerWrapper from '~/components/Segment/ConsentManager'
import { isBrowser, isMobile, isTablet } from 'react-device-detect';

import './static/css/swiper.css'
import './static/fonts/fonts.css'
import { createCookie, createUTMCookie, create_UUID } from '~/utils/cookie'
import ppm from '~/utils/ppm'

let visitor_id = localStorage.getItem("growthbook_visitor_id");

if (!visitor_id) {
  visitor_id = create_UUID();
  localStorage.setItem('growthbook_visitor_id', visitor_id)
}

let deviceType = 'desktop'

if (isMobile) {
  deviceType = 'mobile'
} else if (isTablet) {
  deviceType = 'tablet'
}

const growthbook = new GrowthBook({
  apiHost: process.env.GATSBY_GROWTHBOOK_API_HOST,
  clientKey: process.env.GATSBY_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV === 'development',
  attributes: {
    id: visitor_id,
    isMobileScreenSize: document.body.clientWidth < 992,
    deviceType,
    url: window.location.href
  },
  trackingCallback: (experiment, result) => {
    const params = new URLSearchParams(window.location.search)
    const version = params.get(`gb-${experiment.key}`)

    if (version) return

    localStorage.setItem(`growthbook_experiment_${experiment.key}`, result.key)

    window.analytics.track('Experiment Viewed', {
      experimentId: experiment.key,
      variationId: result.key
    })
  }
})

/**
 * Wraps the apps page element with Segment's Consent Manager.
 *
 * See:
 * https://github.com/segmentio/consent-manager
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
 * https://github.com/segmentio/consent-manager/issues/10#issuecomment-679896358
 */
export const wrapPageElement = ({ element }) => {
  return (
    <ConsentManagerWrapper>
      <GrowthBookProvider growthbook={growthbook}>{element}</GrowthBookProvider>
    </ConsentManagerWrapper>
  )
}

wrapPageElement.propTypes = {
  element: propTypes.element.isRequired
}

const addScript = (url, props) => {
  let script = document.createElement('script')
  script.src = url
  script.async = true

  if (props && props.id) script.id = props.id
  if (props && props.class) script.className = props.class
  if (props && props['data-id']) script.dataset.id = props['data-id']
  if (props && props.onload) script.onload = props.onload

  document.body.appendChild(script)
}

const addImage = (src) => {
  let image = document.createElement('IMG')
  image.setAttribute("src", src);
  image.setAttribute("height", "0");
  image.setAttribute("width", "0");
  image.setAttribute("alt", "");
  image.setAttribute("border", "0")
  image.style.setProperty("display", "none");
  image.style.setProperty("position", "absolute");
  image.style.setProperty("visibility", "hidden");
  document.body.appendChild(image)
}

const addMetaTag = (name, content) => {
  let meta = document.createElement('meta')
  meta.name = name
  meta.content = content
  document.head.appendChild(meta)
}

const getParam = param => new URLSearchParams(window.location.search).get(param)

export const onClientEntry = () => {
  window.ju_num = "5CD54B27-54F9-4336-8F90-04A9550AA39D";
  window.asset_host = '//cdn.jst.ai/'

  window.onload = () => {
    const html = document.querySelector('html')
    html.style.scrollPaddingTop = '118px'

    var geq = window.geq = window.geq || []
    geq.invoked = true
    geq.methods = ["page", "suppress", "trackOrder", "identify", "addToCart"]
    geq.factory = (method) => function () {
      var args = Array.prototype.slice.call(arguments)
      args.unshift(method)
      geq.push(args)
      return geq
    }
    for (var i = 0; i < geq.methods.length; i++) {
      var key = geq.methods[i]
      geq[key] = geq.factory(key)
    }
    geq.SNIPPET_VERSION = "1.5.1"
    setTimeout(() => {
      addScript(`https://s3-us-west-2.amazonaws.com/jsstore/a/WN2HZVK/ge.js${window.location.href.includes('vge=true') ? `?v=${Math.random()}` : ''}`, {
        onload: geq.page()
      })
    }, 90000);

    // TODO: move access token to env variable
    addScript('https://sa.getroster.com/sa.js?token=r0tlaniwek1pv2jrbwrhdg02bhh3zz093U3F7BsrECYCduqzoMsqhxJTQ6te03')

    if (process.env.GATSBY_OCTANE_AI_KEY) {
      addScript(
        `https://app.octaneai.com/${process.env.GATSBY_OCTANE_AI_KEY}/quiz.js`
      )
    }
    if (process.env.GATSBY_OKENDO_SUBSCRIBER_ID) {
      addMetaTag('oke:subscriber_id', process.env.GATSBY_OKENDO_SUBSCRIBER_ID)
      addScript(
        'https://d3hw6dc1ow8pp2.cloudfront.net/reviews-widget-plus/js/okendo-reviews.js'
      )
    }
    if (process.env.GATSBY_TALKABLE_SITE_ID) {
      const onTalkableLoad = () => {
        const paramPage = getParam('traffic_source')
        const detectedCpid = getParam('cpid')

        if (detectedCpid != null) {
          sessionStorage.setItem('cpid', detectedCpid)
        }

        window._talkableq = window._talkableq || []

        window._talkableq.push([
          'authenticate_customer',
          {
            email: '',
            first_name: '',
            last_name: '',
            traffic_source: paramPage
          }
        ])
      }
      addScript(
        `//d2jjzw81hqbuqv.cloudfront.net/integration/clients/${process.env.GATSBY_TALKABLE_SITE_ID}.min.js`,
        {
          onload: onTalkableLoad
        }
      )
    }

    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "n48bq10gx5");

    const activateBEN = () => {
      var o = window.tdl = window.tdl || []
      if (!o.invoked) {
        o.invoked = !0,
          o.methods = ["init", "identify", "convert"],
          o.factory = function (n) {
            return function () {
              var e = Array.prototype.slice.call(arguments);
              return e.unshift(n), o.push(e), o
            }
          }
      }
      for (var e = 0; e < o.methods.length; e++) {
        var n = o.methods[e]; o[n] = o.factory(n)
      }
      addScript('https://js.go2sdk.com/v2/tune.js')
      o.domain = "https://www.inflcr.co"
      tdl.identify()
    }
    activateBEN();

    const miraLoad = () => {
      window.analytics.ready(() => {
        const base = 'https://trkn.us/pixel/conv/ppt=23529;'
        const goalName = 'g=sitewide;'
        const gid = 'gid=57215;'
        const uniqueId = window.analytics.user().anonymousId()
        const sitewideUrl = base + goalName + gid + `ord=[${uniqueId}]`

        addImage(sitewideUrl)
      })
    }
    miraLoad()

    const onPodcornLoad = () => {
      window.podcornDataLayer = window.podcornDataLayer || []
      function podcorn() {
        window.podcornDataLayer.push(arguments);
      };
      podcorn('js', new Date());
      podcorn('config', '845a484c-299e-4ae4-bda7-57eaa0f820c4');
    }

    addScript('https://behaviour.podcorn.com/js/app.js?id=845a484c-299e-4ae4-bda7-57eaa0f820c4', {
      onload: onPodcornLoad
    })

    addScript('https://cdn.jst.ai/vck.js')

    const onSequelSurround = (b, a) => {
      b.saq || (a = b.saq = function () {
        a.callMethod ?
          a.callMethod.apply(a, arguments) : a.queue.push(arguments)
      }, b._saq ||
        (b._saq = a), a.push = a, a.loaded = !0, a.version = "1.0", a.queue = [])
      saq("ts", "opyUV1K84JCL5SAOTB-wbg");
    }

    addScript('https://contentdsp.com/events.js', {
      onload: onSequelSurround(window)
    })

    const onRevRoll = () => {
      const l = 'dataLayer'
      window[l] = window[l] || []
      window[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    }

    {process.env.NODE_ENV === 'production' && (
      addScript(`https://ss.carawayhome.com/pqbftcll.js?id=GTM-TTZRC24`, {
        onload: onRevRoll()
      })
    )}

    addScript('https://sdk.postscript.io/sdk.bundle.js?shopId=4845')

    createCookie('FPC', 7 * 24)
    createUTMCookie('utm_source', 7 * 24)
    createUTMCookie('utm_campaign', 7 * 24)
    createUTMCookie('utm_medium', 7 * 24)
    ppm('visit')

    var customUUID = create_UUID();
    !function() {
      try{
        !function(t,i) {
          if(!i.version) {
            window.tatari = i,
            i.init = function(t,n) {
              var e = function(t,n) {
                i[n] = function() {
                  t.push([n].concat(Array.prototype.slice.call(arguments,0)))
                }
              };
              "track pageview identify".split(" ").forEach(function(t) {
                e(i,t)
              }),
              i._i = t,
              i.config = n,
              i.pageview()
            },
            i.version = "1.2.1";
            var n = t.createElement("script");
            n.type = "text/javascript",
            n.async = !0,
            n.src = "https://d2hrivdxn8ekm8.cloudfront.net/tag-manager/a7db7bfb-92da-40cf-979c-3274f71dddc5-latest.js";
            var e = t.getElementsByTagName("script")[0];
            e.parentNode.insertBefore(n,e)
          }
        }(document,window.tatari||[])
      }
      catch(t) {
        return null
      }
      finally {
        tatari.init('a7db7bfb-92da-40cf-979c-3274f71dddc5');
        tatari.identify(customUUID);
        tatari.pageview();
      }
    }();
  }
}

export const onInitialClientRender = () => {
  const onGladlyLoad = () => {
    window.gladlyConfig = {
      appId: 'carawayhome.com'
    };
  }

  addScript('https://cdn.gladly.com/chat-sdk/widget.js', {
    onload: onGladlyLoad
  })

  const onGrinLoad = () => {
    window.Grin = window.Grin || (window.Grin = [])
  }
  addScript('https://d38xvr37kwwhcm.cloudfront.net/js/grin-sdk.js', {
    onload: onGrinLoad
  })

  addScript('https://app.amped.io/snippet/account_2vbgec2')

  const onTrafficGuard = () => {
    window.dataTrafficGuard = window.dataTrafficGuard || [];
    window.dataTrafficGuard.push(['property', 'tg-009547-001']);
    window.dataTrafficGuard.push(['event', 'pageview', { "afp": true }]);
  }
  addScript('//tgtag.io/tg.js?pid=tg-009547-001', {
    onload: onTrafficGuard(window)
  })

  !function(w, d, t) {
    w.TiktokAnalyticsObject = t;
    var ttq = w[t] = w[t] || [];
    ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"], ttq.setAndDefer = function(t, e) {
        t[e] = function() {
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
        }
    };
    for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
    ttq.instance = function(t) {
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
        return e
    }, ttq.load = function(e, n) {
        var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
        ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = i, ttq._t = ttq._t || {}, ttq._t[e] = +new Date, ttq._o = ttq._o || {}, ttq._o[e] = n || {};
        n = document.createElement("script");
        n.type = "text/javascript", n.async = !0, n.src = i + "?sdkid=" + e + "&lib=" + t;
        e = document.getElementsByTagName("script")[0];
        e.parentNode.insertBefore(n, e)
    };
    ttq.load('CH5EDU3C77U2I5R8JPS0');
    ttq.page();
  }(window, document, 'ttq');
}

export const onRouteUpdate = () => {
  window?.tatari?.pageview();
}